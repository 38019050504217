import React from "react";
import LazyLoad from "react-lazyload";
import { graphql, Link } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import { Button } from "~components/utils/button";
import "./index.scss";
import { AssessmentArticleList } from "~components/articles/assessment-article-list";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section } from "~components/templates/section";
import { DocumentStyle } from "~components/templates/document-style";
import { TableStyle } from "~components/templates/table-style";

type Props = {
  data: GatsbyAssessmentPlatformPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "プラットフォーム診断",
          description:
            "オフィスなどに構築されたネットワーク環境を対象に、ネットワーク機器やサーバーに脆弱性や設定の不備がないか専門のセキュリティエンジニアがオフサイトで診断します。",
        }}
      >
        <div className="Platform AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop PlatformTop">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1 className="AssessmentCommonTop_Title">
                      プラットフォーム診断
                    </h1>
                    <div className="AssessmentCommonTop_Body">
                      オフィスなどに構築されたネットワーク環境を対象に、ネットワーク機器やサーバーに脆弱性や設定の不備がないか専門のセキュリティエンジニアがオフサイトで診断します。
                    </div>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="プラットフォーム診断" />

          <LazyLoad>
            <div className="Process">
              <div className="Process_Wrapper">
                <h2 className="Process_Heading">診断の手順</h2>
                <ul className="Process_StepList">
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">1</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">診断準備</h2>
                        <div className="Process_Note note">
                          脆弱性診断の概要を説明した後、NDA締結と事前ヒアリングを行い最適な診断プランをご提案いたします。その後診断対象の本番環境とは別の開発環境を用意していただき、そこでのリクエスト数/IP数を調査しお見積もりを決定します。お見積金額をご確認いただいた上で納期や診断に関するルールを決定し、契約を締結します。
                        </div>
                      </div>
                    </div>

                    <img
                      className="Process_Triangle"
                      src={data?.triangle?.publicURL || ""}
                      alt=""
                    />
                  </li>
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">2</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">診断</h2>
                        <div className="Process_Note note">
                          専門のセキュリティエンジニアが専用のツールと手動での調査を組み合わせ、網羅的に対象を診断します。診断結果は、診断完了後に報告書にまとめてPDF及びMarkdownで納品します。特に緊急性の高い脆弱性が検出された場合、当日に速報をお出しします。
                        </div>
                      </div>
                    </div>

                    <img
                      className="Process_Triangle"
                      src={data?.triangle?.publicURL || ""}
                      alt=""
                    />
                  </li>
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">3</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">検収</h2>
                        <div className="Process_Note note">
                          報告書には脆弱性の概要、再現方法、対策方法や参考情報など詳しく記載いたしますので、是非改修にお役立てください。
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </LazyLoad>

          <Section>
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <h2>診断項目</h2>
                <p>
                  IPAなどの基準を元に作成した、Flatt
                  Security独自の項目で診断いたします。
                  <br />
                  なお、パブリッククラウドを利用したネットワーク構成においては、カスタマイズにての診断項目のご提案となります。
                </p>
              </DocumentStyle>
            </Common2PaneSectionLayout>
            <Common2PaneSectionLayout>
              <TableStyle>
                <table>
                  <thead>
                    <tr>
                      <th>種別</th>
                      <th>検査項目</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <th>TCP</th>
                      <td>
                        TCPポートスキャン
                        <br />
                        <br />
                        TCPサービススキャン
                      </td>
                    </tr>
                    <tr>
                      <th>UDP</th>
                      <td>
                        UDPポートスキャン
                        <br />
                        <br />
                        UDPサービススキャン
                      </td>
                    </tr>
                    <tr>
                      <th>共通項目</th>
                      <td>
                        サービスのバージョンの確認
                        <br />
                        <br />
                        サービスの設定の確認
                        <br />
                        <br />
                        OSのバージョンの確認
                        <br />
                        <br />
                        OSの設定の確認
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TableStyle>
            </Common2PaneSectionLayout>
          </Section>

          <LazyLoad>
            <div className="Fee">
              <h2 className="Fee_Header">料金</h2>
              <div className="Fee_Wrapper wow fadeInUp">
                <h4 className="Fee_Title">プラットフォーム診断</h4>
                <h2 className="Fee_Price">
                  40
                  <span className="Fee_SmallText">万円から</span>
                </h2>
              </div>
              <div className="Fee_Body body">
                IPアドレスに対して定められた単価をベースとして、お見積もり作業を通じて最終的なIPアドレス数・料金を決定します。
                <p className="Fee_BodyBottom">
                  パブリッククラウドを利用したネットワーク構成においては、カスタマイズでのご提案となるため、上記の参考料金の限りではありません。
                </p>
              </div>
            </div>
          </LazyLoad>
          <AssessmentDocContact />
          <LazyLoad>
            <AssessmentArticleList
              data={{
                articles: data.allContentfulArticle.nodes,
                category: data.contentfulArticleCategory,
              }}
            />
          </LazyLoad>
          <h2 className="AssessmentCommonContact_Title">
            お見積もりは無料です。診断のご依頼はお気軽にお問い合わせください。
          </h2>
          <Link to="/contact">
            <div className="AssessmentCommonQuickContact">
              <Button buttonTheme="Secondary" buttonSize="Large">
                今すぐお問い合わせ
              </Button>
            </div>
          </Link>
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query AssessmentPlatformPage {
    checkbox: file(relativePath: { eq: "checkbox.png" }) {
      publicURL
    }
    document: file(relativePath: { eq: "document.png" }) {
      publicURL
    }
    triangle: file(relativePath: { eq: "assessment/triangle.png" }) {
      publicURL
    }
    allContentfulArticle(
      filter: {
        category: { slug: { eq: "platform" } }
        slug: { ne: "disabled" }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...AssessmentArticleListItemData
      }
    }
    contentfulArticleCategory(slug: { eq: "platform" }) {
      ...AssessmentArticlaListCategory
    }
  }
`;

export default withContext(Component);
